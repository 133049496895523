<template>
  <div>
    <AppBanner :url="require('@/assets/image/cover/products.png')" title="產品" />
    <AppContentNav v-model="activeId" :items="navs" />
    <AppBreadcrumb :items="breadcrumb" :border="false" />
    <div>
      <div class="py-5 pt-lg-6 pb-lg-8 container">
        <img
          v-show="brandInfo.imagePath"
          :src="$imageFilters(brandInfo.imagePath)"
          alt="logo"
          class="m-auto mb-5"
          style="width: auto"
        />
        <div class="px-6 px-lg-8">
          <p class="px-6 px-lg-8 text-gray-500">{{ brandInfo.description }}</p>
        </div>
      </div>
      <ul>
        <li
          v-for="(content, idx) in productItemContent?.items"
          :key="content.id"
          class="p-6 p-lg-8 text-gray-500"
          :class="{ 'bg-gray-100': (idx + 1) % 2 === 1 }"
        >
          <div class="px-4 px-lg-8 container">
            <h2 class="content-title fs-1 text-center mb-5 mb-lg-8">
              {{ content.name }}
            </h2>
            <div class="px-lg-8">
              <div class="row py-4">
                <div class="col-12 col-md-6">
                  <div
                    class="w-100 d-flex justify-content-center align-items-center"
                    :class="{ 'bg-gray-200': !content.imagePath }"
                    style="height: 300px"
                  >
                    <img
                      v-if="content.imagePath"
                      :src="$imageFilters(content.imagePath)"
                      alt="productcontent"
                      class="obj-fit-contain"
                    />
                    <p v-else class="fsz-9 fw-light">無圖片</p>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="row flex-column justify-content-between h-100">
                    <div class="px-6 mb-3">
                      <p class="py-3 fw-light">{{ content.content }}</p>
                    </div>
                    <div class="text-center">
                      <button
                        class="btn btn-secondary text-white rounded-0 fs-5"
                        @click="redirectURL(content.url)"
                        :disabled="!content.url"
                      >
                        了解更多
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { apiGetUserBrandBoard, apiGetBrand } from "@/api/brand";

export default {
  name: "products",
  data() {
    return {
      activeId: this.$route.query.anchor,
      contents: [],
      brandInfo: {},
    };
  },
  computed: {
    brandEn() {
      return this.$route.params.brandEn;
    },
    navs() {
      if (this.contents) {
        return this.contents.map((c) => ({
          id: c.productNameEn,
          name: c.productNameCh,
          path: `/product/${this.brandEn}?anchor=${c.productNameEn}`,
        }));
      }
      return [];
    },
    productItemContent() {
      return this.contents.find((c) => {
        return c.productNameEn === this.activeId;
      });
    },
    currentNav() {
      return this.navs.find((n) => n.id === this.activeId);
    },
    breadcrumb() {
      return [
        {
          title: "產品",
          path: "/product",
        },
        {
          title: this.currentNav?.name || "-",
          path: `/product?type=${this.currentNav?.id}`,
        },
        {
          title: this.brandEn,
        },
      ];
    },
  },
  methods: {
    async getUserBrandBoard() {
      try {
        const { data } = await apiGetUserBrandBoard(this.brandEn);
        this.contents = data.data;
      } catch (e) {
        // regardless
      }
    },
    async getBrandInfo() {
      try {
        const res = await apiGetBrand(this.brandEn);
        const { data } = res;
        this.brandInfo = data.data;
      } catch (e) {
        // regardless
      }
    },
    redirectURL(uri) {
      if (!uri) return;
      window.open(uri, "_blank");
    },
  },
  async created() {
    await this.getUserBrandBoard();
    this.getBrandInfo();
  },
};
</script>
